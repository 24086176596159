<template>
	<div class="h-100">
		<UserForm :id="id" :is-update="isUpdate" @cancel="goToList" @submit="updateUser" />
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_USER, UPDATE_USER } from "../store/action-types"
const { mapActions } = createNamespacedHelpers("users")
export default {
	name: "UpdateUser",
	components: {
		UserForm: () => import("../components/UserForm"),
	},
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.getUserDetail()
	},
	methods: {
		...mapActions({ GET_USER, UPDATE_USER }),
		getUserDetail: async function () {
			const params = {
				userId: this.id,
			}
			await this.GET_USER(params)
		},
		updateUser: async function (params) {
			await this.UPDATE_USER(params)
		},
		goToList() {
			this.$router.push({ name: "UserManagement" })
		},
	},
}
</script>

<style></style>
